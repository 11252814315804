import React, { useEffect, useState } from "react";
import styles from "./redemption-book-flight.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import { DateFormatter, isEmptyChecker } from "../../../../../utils/helper";
import { BookFlightTab } from "../../../../../utils/enum-helper";
import TGDateCalendar from "../../../../../shared/tg-new-datepicker";
import TGFloatingSelect from "../../../../../shared/tg-select";
import { AirportData } from "../../../models/models";
import MultiCityAutosuggest from "../../../component/booking-widget/multi-city/multi-city-autosuggest";

interface RedemptionFlightProps {
  onButtonClick: () => void;
  onPassengerCountChange: () => void;
  options: any;
  onChange: (keyName: string, value: any) => void;
  type: string;
  focused: any;
  setFocused: Function;
  handlePassengerSelection: (e: any, value: any) => void;
  paxCount: string;
  selectedItem?: any;
  parentState?: any;
  isWidget?: boolean;
  widgetParams?: any;
}

const RedemptionBookFlight: React.FC<RedemptionFlightProps> = (
  props: RedemptionFlightProps
) => {
  //This is to verify the screen resolution is desktop or not
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  //This is used to style based on mobile or desktop view
  const dateAlignment = !isDesktopView ? styles.dateMarginAlignment : "ps-0";
  //This is used to allow to use the translation label within the component.
  const { t } = useTranslation();
  //This is used to call the reducer function in the redux store.
  const dispatch = useDispatch();
  const [paxData, setPaxData] = useState<any>([]);
  //This is used to update the know the status of date Data in the book flight Tab.
  const [date, setDate] = useState<any>({
    stDate: null,
    edDate: null,
  });

  const [fromDropdownData, setFromDropdownData] = useState<any>({});
  const [toDropdownData, setToDropdownData] = useState<any>({});
  const [fromDropdownOptionsData, setFromDropdownOptionsData] = useState<any[]>(
    []
  );
  const [toDropdownOptionsData, setToDropdownOptionsData] = useState<any[]>([]);
  const nomineeDetailsInfo = useSelector(
    (state: RootState) => state?.osciReducer?.nominee?.nomineeData
  );
  const profileDetails = useSelector((state: RootState) => state?.osciReducer?.profile?.profileData);
  const flightInfo = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.redemptionBookingData
  );
  const todaysDate = new Date();
  const minDateForRedemption = todaysDate.setDate(todaysDate.getDate() + 1);

  const airAwardAirportList = useSelector(
    (state: RootState) =>
      state?.osciReducer?.airAwardAirport?.airAwardAirportInfo?.data?.data
  );
  //This is used to set Focused value of the option selector.
  const { focused, setFocused } = props;

  const handleClear = (currentField: string, index: number) => {
    if (currentField === "from") {
      setFromDropdownData({});
      setToDropdownOptionsData(airAwardAirportList);
    } else {
      setToDropdownData({});
      // dispatch(
      //   updateRedemptionFieldData({
      //     fieldLabel: "to",
      //     newValue: [],
      //   })
      // );
      if (fromDropdownData?.airportCode === "BKK") {
        const filteredAirAwardAirportList = airAwardAirportList?.filter(
          (x: any) => x?.airportCode !== fromDropdownData?.airportCode
        );
        setToDropdownOptionsData(filteredAirAwardAirportList);
      } else if (fromDropdownData?.airportCode !== "BKK") {
        const bkkAirportData = airAwardAirportList?.filter(
          (x: any) => x?.airportCode === "BKK"
        );
        setToDropdownOptionsData(bkkAirportData);
      } else {
        setToDropdownOptionsData(airAwardAirportList);
      }
    }
  };

  const handleOriginSelection = (e: any, label: string) => {
    console.log("e", e);
    props.onChange(e, label);
    setFromDropdownData(e);
    Object.keys(e)?.length > 0 && setToDropdownData({});
    if (e?.airportCode !== "BKK") {
      const bkkAirportData = airAwardAirportList?.filter(
        (x: AirportData) => x?.airportCode === "BKK"
      );
      console.log("bkkAirport", bkkAirportData);
      e?.length > 0 &&
        e?.airportCode !== "BKK" &&
        setToDropdownData(bkkAirportData?.[0]);
      setToDropdownOptionsData(bkkAirportData);
      // dispatch(
      //   updateRedemptionFieldData({
      //     fieldLabel: "to",
      //     newValue: [...bkkAirportData],
      //   })
      // );
    } else if (e?.airportCode === "BKK") {
      const filteredAirAwardAirportList = airAwardAirportList?.filter(
        (x: any) => x?.airportCode !== e?.airportCode
      );
      setToDropdownOptionsData(filteredAirAwardAirportList);
      setToDropdownData({});
      // dispatch(
      //   updateRedemptionFieldData({
      //     fieldLabel: "to",
      //     newValue: [],
      //   })
      // );
    }
    setFocused({ ...focused, from: false, to: true });
  };

  const handleToSelection = (e: any, label: string) => {
    props.onChange(e, label);
    setFocused({
      ...focused,
      from: false,
      to: false,
      date: true,
      pax: false,
    });
  };

  useEffect(() => {
    if (airAwardAirportList) {
      setFromDropdownOptionsData(airAwardAirportList);
    }
  }, [airAwardAirportList]);

  useEffect(() => {
    if (Object.keys(profileDetails)?.length > 0 && flightInfo?.length > 0) {
      setFromDropdownData(flightInfo?.[0]?.from);
      setToDropdownData(flightInfo?.[0]?.to);
      if (flightInfo?.[0]?.from?.airportCode === "BKK") {
        const filteredAirAwardAirportList = airAwardAirportList?.filter(
          (x: any) => x?.airportCode !== flightInfo?.[0]?.from?.airportCode
        );
        setToDropdownOptionsData(filteredAirAwardAirportList);
      } else if (flightInfo?.[0]?.from?.airportCode !== "BKK") {
        const bkkAirportData = airAwardAirportList?.filter(
          (x: any) => x?.airportCode === "BKK"
        );
        setToDropdownOptionsData(bkkAirportData);
      } else {
        setToDropdownOptionsData(airAwardAirportList);
      }
    }
  }, [profileDetails, flightInfo]);

  useEffect(() => {
    console.log("inside useEffect for", props.parentState);
    if (isDesktopView) {
      if (props.type === BookFlightTab.Return) {
        const startDate = new Date(date?.stDate?.getTime());
        console.log(
          "flightInfo",
          flightInfo?.[1]?.date,
          date?.stDate,
          props?.parentState?.date[1],
          startDate?.setDate(startDate?.getDate() + 1),
          props?.parentState?.date?.[1]
        );
        const newEndDate =
          date?.stDate && !props.parentState?.date?.[1]
            ? startDate?.setDate(startDate?.getDate() + 1)
            : props.parentState?.date?.[1];
        setDate({
          ...date,
          stDate: flightInfo?.[0]?.date || date?.stDate,
          edDate: flightInfo?.[1]?.date ? flightInfo?.[1]?.date : newEndDate,
        });
      } else {
        setDate({
          ...date,
          stDate: flightInfo?.[0]?.date || date?.stDate,
          edDate: null,
        });
      }
    }
  }, [flightInfo, props.type, isDesktopView]);

  useEffect(() => {
    console.log("inside useEffect for", props.parentState);
    if (!isDesktopView && !isEmptyChecker(props.parentState.date)) {
      if (props.type === BookFlightTab.Return) {
        const startDate = new Date(props?.parentState?.date?.[0]?.getTime());
        setDate({
          ...date,
          stDate:
            flightInfo?.[0]?.date ||
            props?.parentState?.date?.[0] ||
            date?.stDate,
          edDate:
            flightInfo?.[1]?.date ||
            (props?.parentState?.date?.[0] && !props.parentState?.date?.[1])
              ? startDate?.setDate(startDate?.getDate() + 1)
              : props.parentState?.date?.[1] || date?.edDate,
        });
      } else {
        setDate({
          ...date,
          stDate:
            flightInfo?.[0]?.date ||
            props.parentState?.date?.[0] ||
            date?.stDate,
          edDate: null,
        });
      }
    }
  }, [flightInfo, props.type, isDesktopView, props.selectedItem]);

  useEffect(() => {
    if (nomineeDetailsInfo && Object.keys?.(nomineeDetailsInfo)?.length) {
      const nomineeCount = nomineeDetailsInfo?.nomineeDetail?.length;
      const maxPaxCount =
        !isNaN(nomineeCount) && nomineeCount >= 4 ? 4 : nomineeCount + 1;
      const resultArr = [...Array(maxPaxCount)].map((x: any, index: number) => {
        return {
          listName: index + 1,
        };
      });
      setPaxData(resultArr);
    }
  }, [nomineeDetailsInfo]);

  useEffect(() => {
    let fromDropdownWidgetOptionsData;
    let fromDropdownData;
    let toAirport;
    if (
      props?.isWidget &&
      airAwardAirportList &&
      airAwardAirportList.length > 0
    ) {
      const bkkAirportData = airAwardAirportList?.filter(
        (x: AirportData) => x?.airportCode === "BKK"
      );
      const otherAirportData = airAwardAirportList?.filter(
        (x: AirportData) => x?.airportCode !== "BKK"
      );
      fromDropdownWidgetOptionsData = airAwardAirportList?.filter(
        (x: any) => x?.originDisp
      );
      setFromDropdownOptionsData(fromDropdownWidgetOptionsData);
      if (
        !isEmptyChecker(props?.widgetParams?.params?.originAirport) &&
        !isEmptyChecker(props?.widgetParams?.params?.departureAirport)
      ) {
        fromDropdownData = fromDropdownWidgetOptionsData.filter(
          (x: any) =>
            x.airportCode === props?.widgetParams?.params?.originAirport
        );

        setFromDropdownData(fromDropdownData[0]);
        setToDropdownData({});
        if (props?.widgetParams?.params?.originAirport !== "BKK") {
          setToDropdownOptionsData(bkkAirportData);
          setToDropdownData(bkkAirportData[0]);
        } else {
          setToDropdownOptionsData(otherAirportData);
          toAirport = otherAirportData.filter(
            (x: any) =>
              x.airportCode === props?.widgetParams?.params?.departureAirport
          );
          setToDropdownData(toAirport[0]);
        }
      } else {
        setFromDropdownData({});
      }

      if (props?.widgetParams?.params?.tripType === "Return") {
        if (
          !isEmptyChecker(props?.widgetParams?.params?.startDate) ||
          !isEmptyChecker(props?.widgetParams?.params?.endDate)
        ) {
          setDate({
            stDate: DateFormatter(props?.widgetParams?.params?.startDate),
            edDate: DateFormatter(props?.widgetParams?.params?.endDate),
          });
        } else {
          setDate({ stDate: null, edDate: null });
        }
      } else {
        if (!isEmptyChecker(props?.widgetParams?.params?.startDate)) {
          setDate({
            stDate: DateFormatter(props?.widgetParams?.params?.startDate),
          });
        } else {
          setDate({ stDate: null, edDate: null });
        }
      }
      if (!isEmptyChecker(props?.widgetParams?.params?.passengers)) {
        if (props?.widgetParams?.params?.passengers?.isRedemption === "true") {
          const resultArr = [
            ...Array(
              parseInt(props?.widgetParams?.params?.passengers?.redemptionCount)
            ),
          ].map((x: any, index: number) => {
            return {
              listName: index + 1,
            };
          });
          setPaxData(resultArr);
        }
      }
    }
  }, [props?.isWidget, airAwardAirportList]);

  return (
    <div className="d-flex flex-column">
      <div className={styles.flexContainerRedemption}>
        <div>
          <div className={styles["multicity-destination-container"]}>
            <MultiCityAutosuggest
              label={t("label_book_widget_from")}
              customClassName="fromDropdownBorderRadius"
              onChange={() => {}}
              onSelect={handleOriginSelection}
              searchPlaceHolder={t("label_multi_city_search_placeholder")}
              name="From"
              options={fromDropdownOptionsData?.filter(
                (x: any) => x?.originDisp
              )}
              id="redemptionFrom"
              rowIndex={0}
              recentAirport={[]}
              onClearRecentSearch={() => {}}
              selectedPropsValue={fromDropdownData}
              onClear={(i: number) => handleClear("from", i)}
              reverseArrow={false}
              isFocused={focused.from}
              onAutoSuggestBlur={() => {
                setFocused({
                  ...focused,
                  from: false,
                  to: false,
                  date: false,
                  pax: false,
                });
              }}
              listHeader={t("label_redemption_airports")}
            />

            <MultiCityAutosuggest
              label={t("label_book_widget_to")}
              customClassName="toDropdownBorderRadius"
              onChange={() => {}}
              onSelect={handleToSelection}
              searchPlaceHolder={t("label_multi_city_search_placeholder")}
              name="To"
              options={toDropdownOptionsData?.filter(
                (z: any) => z?.destinationDisp
              )}
              rowIndex={0}
              selectedPropsValue={toDropdownData}
              onClear={(i: number) => handleClear("to", i)}
              reverseArrow={false}
              recentAirport={[]}
              onClearRecentSearch={() => {}}
              isFocused={focused.to}
              onAutoSuggestBlur={() => {
                setFocused({
                  ...focused,
                  from: false,
                  to: false,
                  date: false,
                  pax: false,
                });
              }}
              listHeader={t("label_redemption_airports")}
            />
          </div>
        </div>
        <div>
          <TGDateCalendar
            label="date"
            onChange={(e, label) => props.onChange(e, label)}
            type={
              !isDesktopView
                ? props?.selectedItem[1] === "0"
                  ? "2way"
                  : "1way"
                : props.type === BookFlightTab?.Return
                ? "2way"
                : "1way"
            }
            dateData={date}
            setDateData={setDate}
            onReset={() => {}}
            focused={props.focused}
            setFocused={setFocused}
            onDateBlur={() => {}}
            name="multicity"
            minDate={minDateForRedemption}
            hideFare={true}
          />
        </div>
      </div>
      <div className={styles["pax-wrapper"]}>
        <TGFloatingSelect
          label={t("label_book_widget_passengers")}
          listData={paxData}
          onChange={() => {}}
          isLoading={false}
          isReadOnly={true}
          defaultValue={props.paxCount || "1"}
          onSelect={props.handlePassengerSelection}
          focused={props.focused}
          disableSearch={true}
        />
      </div>
    </div>
  );
};

export default RedemptionBookFlight;
