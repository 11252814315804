import React, { useEffect, useState } from "react";
import styles from "./date-selection-widget.module.scss";
import moment from "moment";
import TGIcon from "../../../../../shared/tg-icon";
import {
  clearFlightInfoData,
  clearFlightInfoDatawithId,
  flightInfoRequest,
  updateRedemptionBookingDates,
} from "../../../slice/flightInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../../utils/hook/useScreenSize";

const DateSelectionWidget: React.FC<any> = ({ iteration, flightDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const bookingDates = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.redemptionBookingDates
  );
  const minMilesObj = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.minMilesObj
  );
  const flightInfoRes = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.flightInfoResponse
  );
  // setting of selected date
  const [selectedDate, setSelectedDate] = useState(moment(flightDetails?.date));
  // state for enabling and disabling prev and next arrows and logic for manipulating same
  const [disablePrevArrow, setDisablePrevArrow] = useState(false);
  const [disableNextArrow, setDisableNextArrow] = useState(false);
  const setUpdatePrevNextArrows = (date: moment.Moment) => {
    let prevWeekDate = date.clone().subtract(1, "weeks");
    const flag1 = validateDate(prevWeekDate);
    flag1 ? setDisablePrevArrow(false) : setDisablePrevArrow(true);
    let nextWeekDate = date.clone().add(1, "weeks");
    const flag2 = validateDate(nextWeekDate);
    flag2 ? setDisableNextArrow(false) : setDisableNextArrow(true);
  };

  useEffect(() => {
    setSelectedDate(moment(flightDetails?.date));
    setUpdatePrevNextArrows(moment(flightDetails?.date));
  }, [flightDetails?.date]);
  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the scroll
      windowHeight > 150
        ? setStickyClass("dateStrickyView")
        : setStickyClass("");
    }
  };

  //Date validation logic
  const validateDate = (date: moment.Moment) => {
    if (flightDetails?.journeyType === "One-way") {
      ///if trip type is one way, the validation should be:
      ///1. Date can be move forward until 360 days in advance
      ///2. backward not less than today+1
      if (date.isBefore(moment())) {
        return false;
      } else if (date.isAfter(moment().clone().add(1, "years"))) {
        return false;
      }
    } else if (flightDetails?.journeyType === "Return") {
      /// Departure histogram, Date can be move forward until return date and move backward not less than today+1.
      if (flightDetails?.triptype === "departure") {
        if (date.isBefore(moment())) {
          return false;
        } else if (date.isSameOrAfter(bookingDates[1])) {
          return false;
        }
      }
      /// Return histogram, Date can be move forward until 360 days in advance and move backward not less than depart date.
      else if (flightDetails?.triptype === "arrival") {
        if (date.isSameOrBefore(bookingDates[0])) {
          return false;
        } else if (date.isAfter(moment().clone().add(1, "years"))) {
          return false;
        }
      }
    } else {
      // multicity backtracking
      if (flightDetails?.itneraryOrder === 1 && date.isBefore(moment())) {
        return false;
      } else if (
        flightDetails?.itneraryOrder === 2 &&
        date.isBefore(flightDetails?.date)
      ) {
        return false;
      } else if (
        // date.isAfter(moment(bookingData[0]?.date).clone().add(2, "days")) &&
        flightDetails?.itneraryOrder === 2 &&
        date.isAfter(moment(flightDetails?.date).clone().add(2, "days")) &&
        flightDetails?.isBackTracking
      ) {
        return false;
      }
      if (flightDetails?.itneraryOrder === 3 && date.isBefore(moment())) {
        return false;
      } else if (
        flightDetails?.itneraryOrder === 4 &&
        date.isBefore(flightDetails?.date)
      ) {
        return false;
      } else if (
        flightDetails?.itneraryOrder === 4 &&
        date.isAfter(moment(flightDetails?.date).clone().add(2, "days")) &&
        flightDetails?.isBackTracking
      ) {
        return false;
      }
    }
    return true;
  };

  // populating the dates array object
  const getDisplayDates = (date: moment.Moment) => {
    let dates = [];
    for (let i = -3; i <= 3; i++) {
      let nextDate = date.clone().add(i, "days");
      let isActive = validateDate(nextDate);
      dates?.push({ date: nextDate, isActive: isActive });
    }
    return dates;
  };

  let displayDates = getDisplayDates(selectedDate);

  //api payload creation helper method
  const createFlightListPayload = (date: moment.Moment) => {
    const reqObj: any = {
      id: iteration,
      info: {
        flightInfo: {
          departureDate: moment(date).format("DDMMYY"),
          departure: flightDetails?.from?.airportCode,
          arrival: flightDetails?.to?.airportCode,
        },
      },
    };

    return reqObj;
  };

  //logic to update date array for validation
  const createDateUpdatePayload = (date: moment.Moment) => {
    const updObj: any = {
      id: iteration,
      updatedDate: date,
    };
    return updObj;
  };

  const handlePrevWeek = () => {
    const prevWeekDate = selectedDate.clone().subtract(1, "weeks");
    //const prevDateTovalidate =  selectedDate.clone().subtract(3, 'days');
    if (validateDate(prevWeekDate) === true) {
      setSelectedDate(prevWeekDate);
      const req = createFlightListPayload(prevWeekDate);
      dispatch(clearFlightInfoData(req));
      dispatch(flightInfoRequest(req));
      const updReq = createDateUpdatePayload(prevWeekDate);
      dispatch(updateRedemptionBookingDates(updReq));
      setUpdatePrevNextArrows(prevWeekDate);
    } else {
      setDisablePrevArrow(true);
    }
  };

  const handleNextWeek = () => {
    const nextWeekDate = selectedDate.clone().add(1, "weeks");
    //const nextDateTovalidate =  selectedDate.clone().add(3, 'days');
    if (validateDate(nextWeekDate) === true) {
      setSelectedDate(nextWeekDate);
      const req = createFlightListPayload(nextWeekDate);
      dispatch(clearFlightInfoData(req));
      dispatch(flightInfoRequest(req));
      dispatch(clearFlightInfoDatawithId(flightDetails));
      const updReq = createDateUpdatePayload(nextWeekDate);
      dispatch(updateRedemptionBookingDates(updReq));
      setUpdatePrevNextArrows(nextWeekDate);
    } else {
      setDisableNextArrow(true);
    }
  };

  const handleDateClick = (date: moment.Moment) => {
    if (validateDate(date) === true) {
      setSelectedDate(date);
      const req = createFlightListPayload(date);
      dispatch(clearFlightInfoData(req));
      dispatch(flightInfoRequest(req));
      const updReq = createDateUpdatePayload(date);
      dispatch(updateRedemptionBookingDates(updReq));
      setUpdatePrevNextArrows(date);
    }
  };

  return (
    <div
      key={iteration}
      className={`global-content-margin ${styles.calendarTab}`}
    >
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.containerDiv}>
            <div
              className={`${styles.prevArrow} ${
                disablePrevArrow ? styles.disabledDateItem : ""
              }`}
              onClick={() => handlePrevWeek()}
            >
              <TGIcon
                fillColor="none"
                icon={"chevron-left-white-icon"}
                size="24"
              ></TGIcon>
            </div>
            <div className={styles.datesContainer}>
              {displayDates.map((date, index) => (
                <div
                  key={index}
                  className={
                    date?.date?.isSame(selectedDate)
                      ? styles.dateItemSelected
                      : styles.dateItemContainer
                  }
                  onClick={() => handleDateClick(date?.date)}
                >
                  <div className={styles.dateInfo}>
                    <div className={styles.dayAndIcon}>
                      <span
                        className={`${styles.day} ${
                          !date?.isActive ? styles.disabledDateItem : ""
                        }`}
                      >
                        {date?.date?.format("dddd").substring(0, 3)}
                      </span>
                      {/* {(date?.date?.isSame(selectedDate) && flightInfoRes[iteration]?.data?.flightList?.length > 0) && <span className={styles.greenCircle}><TGIcon icon={"green-circle"} size={"8px"} fillColor={"#55CD7D"}></TGIcon></span>} */}
                    </div>
                    <span
                      className={`${styles.date} ${
                        !date?.isActive ? styles.disabledDateItem : ""
                      }`}
                    >
                      {date?.date?.format("DD MMM YYYY")}
                    </span>
                  </div>
                  {minMilesObj[iteration] &&
                    flightDetails?.journeyType !== "Multi-City" &&
                    date?.date?.isSame(selectedDate) && (
                      <div className={styles.milesContainer}>
                        <span className={styles.milesInfo}>
                          {minMilesObj[iteration]}
                        </span>
                        <span className={styles.unit}>
                          {t("label_redemption_header_miles")}
                        </span>
                      </div>
                    )}
                </div>
              ))}
            </div>
            <div
              className={`${styles.nextArrow} ${
                disableNextArrow ? styles.disabledDateItem : ""
              }`}
              onClick={() => handleNextWeek()}
            >
              <TGIcon
                fillColor="none"
                icon={"chevron-right-white-icon"}
                size="24"
              ></TGIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateSelectionWidget;
