import React, { useEffect, useState } from "react";
import styles from "./redemption-booking-widget.module.scss";
import RedemptionBookFlight from "./redemption-book-flight";
import { BookFlightTab } from "../../../../utils/enum-helper";
import TGToggle from "../../../../shared/tg-toggle";
import { useTranslation } from "react-i18next";
import MultiCity from "./redemption-multi-city/index";
import TGTabs from "../../../../shared/tg-tabs";
import { Button, Offcanvas } from "react-bootstrap";
import TGButton from "../../../../shared/tg-button";
import { useDispatch, useSelector } from "react-redux";
import { airportRedemptionDataRequest } from "../../slice/redemptionSlice";
import { getTierImg } from "../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import {
  saveRedemptionBookingData,
  saveRedemptionBookingDates,
  saveRuleSetId,
  clearSelectedFlightIndex,
  disable_continue_btn,
  clearSelectedFlightInfoData,
} from "../../slice/flightInfoSlice";
import moment from "moment";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import { TGRadio } from "../../../../shared/tg-radio";
import TGIcon from "../../../../shared/tg-icon";
import TGModal from "../../../../shared/tg-modal";
import { airAwardAirportRequest } from "../../slice/airAwardAirportSlice";
import isAuthenticate from "../../../../utils/hook/isAuthenticate";
import { useClassObserver } from "../../../../utils/hook/useClassObserver";
import { backTrackingAirportRequest } from "../../slice/backTrackingAirportSlice";
import { resetPersistStore } from "../../../../store";
import { RootState } from "../../slice/RootReducer";

interface Props {
  redeemState: boolean;
  handleRedeemInactive: () => void;
  selectedTab?: any;
  isDesktopView?: boolean;
  currentActiveTabIndex?: any;
  setCurrentActiveTabIndex?: any;
  isOffCanvas: boolean;
  onHideCanvas?: Function;
  showEditSummary: any;
  isWidget?: boolean;
  widgetParams?: any;
}

const RedemptionBookingWidget: React.FC<Props> = (props: Props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<any>([0, "0"]);
  const dispatch = useDispatch();
  const profileDetails = useSelector(
    (state: RootState) => state?.osciReducer?.profile?.profileData
  );
  const flightInfo = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.redemptionBookingData
  );
  const [state, setState] = useState<any>({
    From: {},
    To: {},
    date: "",
  });
  console.log("flightInfo", state, flightInfo);
  const navigate = useNavigate();
  //These are the values of the sub-tabs in the Book Flight section.
  const { t, i18n } = useTranslation();
  const bookFlightTabs: any[] = [
    { value: BookFlightTab.Return, label: t("label_book_widget_return") },
    { value: BookFlightTab.OneWay, label: t("label_book_widget_one_way") },
    {
      value: BookFlightTab.MultiCity,
      label: t("label_book_widget_multi_city"),
    },
  ];

  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;

  //This shows the total number of MultiCity Rows
  const [multiCityCount, setMultiCityCount] = useState<number>(2);
  const [showInfo, setShowInfo] = useState(false);
  const [currentVendor, setCurrentVendor] = useState(1);
  //This is used to store the selected Tab in the Book Flights section with initial value as Return.
  const [selectedItem, setSelectedItem] = useState<string>(
    BookFlightTab.Return
  );
  //To handle Focus for Return and One-way
  const [focused, setFocused] = useState({
    from: false,
    to: false,
    date: false,
    pax: false,
  });

  const [paxCount, setPaxCount] = useState("1");
  const userAuth = isAuthenticate();
  const isBWFieldExist = useClassObserver("booking-widget-overlay");

  useEffect(() => {
    if (isDesktopView) {
      if (isBWFieldExist) {
        document.documentElement.style.setProperty(
          "--everymundo-z-index",
          "-1"
        );
      } else {
        document.documentElement.style.setProperty("--everymundo-z-index", "1");
      }
    }
  }, [isBWFieldExist, isDesktopView]);

  useEffect(() => {
    dispatch(clearSelectedFlightInfoData());
    dispatch(clearSelectedFlightIndex());
    resetPersistStore();
    if (sessionStorage.getItem("entry")) {
      sessionStorage.removeItem("entry");
    }
  }, []);

  useEffect(() => {
    if (userAuth) {
      dispatch(airportRedemptionDataRequest());
      dispatch(airAwardAirportRequest());
      dispatch(backTrackingAirportRequest());
    }
  }, [dispatch, userAuth]);

  useEffect(() => {
    if (props.currentActiveTabIndex && isDesktopView) {
      setSelectedItem(props.currentActiveTabIndex);
    }
    if (props.currentActiveTabIndex && !isDesktopView) {
      setCurrentTabIndex([currentTabIndex[0], props.currentActiveTabIndex]);
    }
  }, [props.currentActiveTabIndex, isDesktopView]);

  useEffect(() => {
    if (Object.keys(profileDetails)?.length > 0 && flightInfo?.length > 0) {
      setState({
        ...state,
        From: [flightInfo?.[0]?.from],
        To: [flightInfo?.[0]?.to],
        date: [
          new Date(flightInfo?.[0]?.date),
          new Date(flightInfo?.[1]?.date),
        ],
      });
      flightInfo?.[0]?.journeyType === "Multi-City"
        ? setSelectedItem("Multi-city")
        : setSelectedItem(flightInfo?.[0]?.journeyType);
      flightInfo?.[0]?.journeyType === "Multi-City"
        ? setCurrentTabIndex([currentTabIndex[0], "2"])
        : flightInfo?.[0]?.journeyType?.includes("One")
        ? setCurrentTabIndex([currentTabIndex[0], "1"])
        : setCurrentTabIndex([currentTabIndex[0], "0"]);
      setPaxCount(flightInfo?.[0]?.pax);
    }
  }, [profileDetails, flightInfo]);

  const handlePassengerSelection = (e: any, selectedValue: any) => {
    console.log("valueCheck", selectedValue);
    setPaxCount(selectedValue?.listName);
  };

  //The selected card data is also reset when switching tabs.
  const handleDesktopTabSelection = (selected: any) => {
    setSelectedItem(selected);
    props?.setCurrentActiveTabIndex &&
      props?.setCurrentActiveTabIndex(selected);
  };

  const handleFieldChange = (value: any, keyName: string) => {
    console.log("entered", value, keyName);
    let array: any = [];
    array.push(value);
    if (keyName === "From" || keyName === "To") {
      setState({ ...state, [keyName]: array });
    } else {
      setState({ ...state, [keyName]: value });
    }
  };

  //This is used when user makes the decision to move away from Miles redemption
  const handleRedeemMilesSelection = () => {
    props.handleRedeemInactive();
    document.documentElement.style.setProperty("--loading-spinner", "block");
    setTimeout(() => {
      document.documentElement.style.setProperty("--loading-spinner", "none");
    }, [500]);
  };
  const handleTabSelect = (selectedTab: number) => {
    setCurrentTabIndex([currentTabIndex[0], selectedTab]);
    props?.setCurrentActiveTabIndex &&
      props?.setCurrentActiveTabIndex([currentTabIndex[0], selectedTab]);
    if (selectedTab?.toString() === "1") {
      setSelectedItem(BookFlightTab?.OneWay);
    } else if (selectedTab?.toString() === "2") {
      setSelectedItem(BookFlightTab?.MultiCity);
    } else {
      setSelectedItem(BookFlightTab?.Return);
    }
  };

  const handleSearchFlight = async () => {
    console.log("test test test testch");
    resetPersistStore();
    let reqArr = [];
    // New Format
    if (selectedItem?.toLowerCase().includes("one")) {
      const reqObj = {
        pax: paxCount,
        from: state?.From?.[0],
        to: state?.To?.[0],
        triptype: "departure",
        date: state?.date[0],
        journeyType: "One-way",
      };
      reqArr.push(reqObj);
      if (state?.To?.[0]?.airportCode === "BKK") {
        dispatch(saveRuleSetId(1));
      } else if (state?.From?.[0]?.airportCode === "BKK") {
        dispatch(saveRuleSetId(2));
      }
    } else {
      const reqObjRoundTrip = [
        {
          pax: paxCount,
          from: state?.From?.[0],
          to: state?.To?.[0],
          triptype: "departure",
          date: state?.date[0],
          journeyType: "Return",
        },
        {
          pax: paxCount,
          from: state?.To?.[0],
          to: state?.From?.[0],
          triptype: "arrival",
          date: state?.date[1],
          journeyType: "Return",
        },
      ];
      reqArr.push(...reqObjRoundTrip);
      if (state?.To?.[0]?.airportCode === "BKK") {
        dispatch(saveRuleSetId(3));
      } else if (state?.From?.[0]?.airportCode === "BKK") {
        dispatch(saveRuleSetId(4));
      }
    }
    // reqArr.forEach((x: any) => {
    //   dispatch(flightInfoRequest(x));
    // });
    dispatch(saveRedemptionBookingData(reqArr));
    let dateArr = [...state?.date];
    dispatch(saveRedemptionBookingDates(dateArr));
    //quick fix to hide edit widget on select flight on search click
    const elem = document?.getElementById(
      "redemption-select-flight-edit-widget"
    );
    if (elem) {
      elem.style.visibility = "hidden";
    }

    if (props.isOffCanvas && props.onHideCanvas) {
      props.onHideCanvas();
    }
    // show edit search summary once edited in select flight page
    if (props?.showEditSummary !== undefined) {
      props?.showEditSummary(false);
    }
    // clearing selected flight while landing widget page
    dispatch(clearSelectedFlightIndex());
    dispatch(disable_continue_btn(true));
    dispatch(clearSelectedFlightInfoData());
    navigate(`/${i18n.language}/redemption/selectflight`, { replace: true });
  };

  //To get Tier Image as per profile api response
  const getMemberTierImg = () => {
    const currentTier = profileDetails?.privilegeCard?.toLowerCase();
    return getTierImg(currentTier);
  };

  const renderTGInfo = () => (
    <>
      <h2 className={styles["modal-header"]}>
        Welcome to instant Air Awards Redemption on THAI
      </h2>
      <div className={styles["modal-desc"]}>
        To help you complete your reservation, please note the following
      </div>
      <ul className={styles["modal-desc"]}>
        <li>You may redeem for yourself, or your Award Nominees</li>
        <li>
          Reservations can be made no less than 2 days prior to departure. You
          may reserve up to 340 days in advance.
        </li>
        <li>Payment for Tax and Service Fee can be made by credit card</li>
      </ul>
      <h4 className={styles["modal-sub-header"]}>Credit Card Payment</h4>
      <ul className={styles["modal-desc"]}>
        <li>eTickets are issued instantly on-line.</li>
      </ul>
    </>
  );

  const renderStarInfo = () => (
    <>
      <h2 className={styles["modal-header"]}>Star Alliance Awards</h2>
      <div className={styles["modal-desc"]}>
        Online Star Alliance Awards enable you to instantly book and ticket
        Award travel to your preferred destination on the world’s largest
        airline network.At this time, bookings can be made only for travels
        departing from Bangkok. Additional departure points will soon be
        progressively available.Select award routing and create your itinerary.
        Once seating is confirmed on all segments, payment of applicable taxes
        and surcharges can be made by credit card.
        <br />
        Terms and Conditions:
      </div>
      <ul className={styles["modal-desc"]}>
        <li>
          Star Alliance Awards can be redeemed for travel with Star Alliance
          partners
        </li>
        <li>
          At this time, departure flights must be from Bangkok only, with
          maximum of 4 flight segments per itinerary for online Star Alliance
          Awards. Stop Over or Open Jaw routes are not permitted.
        </li>
        <li>
          You may redeem Star Alliance Awards for yourself, or your Award
          Nominees.
        </li>
        <li>
          Reservations can be made no less than 2 days prior to departure. You
          may reserve up to 340 days in advance.
        </li>
        <li>
          Payment for taxes and surcharge can be made by credit card. E-tickets
          are issued instantly online.
        </li>
        <li>
          Complete terms and conditions, click here. You can also review Terms &
          Conditions during the booking process
        </li>
      </ul>
    </>
  );

  const handleRadioChange = (e) => {
    setCurrentVendor(e?.id);
  };

  const renderRadioComponent = () => (
    <TGRadio
      name="region-name"
      options={[
        { id: 1, label: t("label_redemption_thai_airways_radio") },
        { id: 2, label: t("label_redemption_star_alliance_radio") },
      ]}
      onChange={(e) => handleRadioChange(e)}
      isBorder={!isDesktopView}
      selected={1}
    />
  );

  //Selection of the desktop tabs based on the user selection in redeem miles
  const renderRedeemTabContent = () => {
    switch (selectedItem) {
      case "Return":
        return (
          <RedemptionBookFlight
            options={[]}
            onButtonClick={() => {}}
            onPassengerCountChange={() => {}}
            onChange={handleFieldChange}
            type={BookFlightTab.Return}
            focused={focused}
            setFocused={setFocused}
            handlePassengerSelection={handlePassengerSelection}
            paxCount={paxCount}
            selectedItem={selectedItem}
            parentState={state}
            isWidget={props?.isWidget}
            widgetParams={props?.widgetParams}
          />
        );
      case "One-way":
        return (
          <RedemptionBookFlight
            options={[]}
            onButtonClick={() => {}}
            onPassengerCountChange={() => {}}
            onChange={handleFieldChange}
            type={BookFlightTab.OneWay}
            focused={focused}
            setFocused={setFocused}
            handlePassengerSelection={handlePassengerSelection}
            paxCount={paxCount}
            selectedItem={selectedItem}
            parentState={state}
            isWidget={props?.isWidget}
            widgetParams={props?.widgetParams}
          />
        );
      case "Multi-city":
        return (
          <MultiCity
            onPassengerCountChange={() => {}}
            onClassChange={() => {}}
            onChangeInput={() => {}}
            options={[]}
            onInputClick={() => {}}
            type="MultiCity"
            handleUpdate={() => {}}
            count={multiCityCount}
            showEditSummary={props.showEditSummary}
          />
        );
      default:
        return null;
    }
  };

  //Render the mobile view content
  const renderRedeemMobileTabContent = () => {
    return (
      <TGTabs
        variant="underline"
        onSelect={handleTabSelect}
        title={t("label_book_widget_return")}
        className="tg-tabs-subClass-widget"
        optionalClassName="tg-tabs-custom-tab-container"
        activeIndex={currentTabIndex}
      >
        <div title={t("label_book_widget_return")}>
          {!props.isOffCanvas && (
            <div className={`${styles.buttonContainer}`}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex gap-3">
                  {getMemberTierImg() && (
                    <img
                      src={getMemberTierImg()}
                      height={"24px"}
                      width={"48px"}
                    />
                  )}
                  <div className={styles["rop_member_id"]}>
                    {profileDetails?.memberID}
                  </div>
                </div>
                <div className={styles["rop_miles"]}>
                  {profileDetails?.remainingMiles}
                  {profileDetails?.remainingMiles && " Miles"}
                </div>
              </div>
              {(!props.isWidget ||
                (props.isWidget &&
                  props?.widgetParams?.params?.redemption)) && (
                <TGToggle
                  variant="secondary"
                  buttonExists={false}
                  label={`${t("label_book_widget_redeem_miles")}`}
                  active={props.redeemState}
                  onClick={handleRedeemMilesSelection}
                  isInfoIcon={true}
                  onInfoClick={() => setShowInfo(true)}
                />
              )}
            </div>
          )}
          <div className={styles["mobile-radio-wrapper"]}>
            {renderRadioComponent()}
          </div>
          {Number(currentTabIndex?.[1]) === 0 && (
            <RedemptionBookFlight
              options={[]}
              onButtonClick={() => {}}
              onPassengerCountChange={() => {}}
              onChange={handleFieldChange}
              type={BookFlightTab.Return}
              focused={focused}
              setFocused={setFocused}
              handlePassengerSelection={handlePassengerSelection}
              paxCount={paxCount}
              selectedItem={currentTabIndex}
              parentState={state}
              isWidget={props?.isWidget}
              widgetParams={props?.widgetParams}
            />
          )}
        </div>

        <div title={t("label_book_widget_one_way")}>
          {!props.isOffCanvas && (
            <div className={`${styles.buttonContainer}`}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex gap-3">
                  {getMemberTierImg() && (
                    <img
                      src={getMemberTierImg()}
                      height={"24px"}
                      width={"48px"}
                    />
                  )}
                  <div className={styles["rop_member_id"]}>
                    {profileDetails?.memberID}
                  </div>
                </div>
                <div className={styles["rop_miles"]}>
                  {profileDetails?.remainingMiles}
                  {profileDetails?.remainingMiles && " Miles"}
                </div>
              </div>
              {(!props.isWidget ||
                (props.isWidget &&
                  props?.widgetParams?.params?.redemption)) && (
                <TGToggle
                  variant="secondary"
                  buttonExists={false}
                  label="Redeem Miles"
                  active={props.redeemState}
                  onClick={handleRedeemMilesSelection}
                  isInfoIcon={true}
                  onInfoClick={() => setShowInfo(true)}
                />
              )}
            </div>
          )}
          <div className={styles["mobile-radio-wrapper"]}>
            {renderRadioComponent()}
          </div>
          {currentTabIndex?.[1] === "1" && (
            <RedemptionBookFlight
              options={[]}
              onButtonClick={() => {}}
              onPassengerCountChange={() => {}}
              onChange={handleFieldChange}
              type={BookFlightTab.OneWay}
              focused={focused}
              setFocused={setFocused}
              handlePassengerSelection={handlePassengerSelection}
              paxCount={paxCount}
              selectedItem={currentTabIndex}
              parentState={state}
              isWidget={props?.isWidget}
              widgetParams={props?.widgetParams}
            />
          )}
        </div>

        <div title={t("label_book_widget_multi_city")}>
          {!props.isOffCanvas && (
            <div className={`${styles.buttonContainer}`}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex gap-3">
                  {getMemberTierImg() && (
                    <img
                      src={getMemberTierImg()}
                      height={"24px"}
                      width={"48px"}
                    />
                  )}
                  <div className={styles["rop_member_id"]}>
                    {profileDetails?.memberID}
                  </div>
                </div>
                <div className={styles["rop_miles"]}>
                  {profileDetails?.remainingMiles}
                  {profileDetails?.remainingMiles && " Miles"}
                </div>
              </div>
              {(!props.isWidget ||
                (props.isWidget &&
                  props?.widgetParams?.params?.redemption)) && (
                <TGToggle
                  variant="secondary"
                  buttonExists={false}
                  label={t("label_book_widget_redeem_miles")}
                  active={props.redeemState}
                  onClick={handleRedeemMilesSelection}
                  isInfoIcon={true}
                  onInfoClick={() => setShowInfo(true)}
                />
              )}
            </div>
          )}
          <div className={styles["mobile-radio-wrapper"]}>
            {renderRadioComponent()}
          </div>
          {currentTabIndex?.[1] === "2" && (
            <MultiCity
              onPassengerCountChange={() => {}}
              onClassChange={() => {}}
              onChangeInput={() => {}}
              options={[]}
              onInputClick={() => {}}
              type="MultiCity"
              handleUpdate={() => {}}
              count={multiCityCount}
              showEditSummary={props.showEditSummary}
            />
          )}
        </div>
      </TGTabs>
    );
  };
  return (
    <>
      {isDesktopView && (
        <>
          <div className="d-flex flex-column gap-3">
            <div className={styles.flexContainer}>
              <div>
                {bookFlightTabs.map((item: any) => (
                  <button
                    key={item}
                    className={`${styles.item} ${
                      selectedItem === item.value ? `${styles.selected}` : ""
                    }`}
                    onClick={() => handleDesktopTabSelection(item.value)}
                    title={item.value}
                    aria-label={`Book Flight Tab ${item.value}`}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
              <div>
                <div
                  className={`${styles.buttonContainer}`}
                  title={t("label_book_widget_redeem_miles")}
                  id="redeemButtonContainer"
                >
                  <div className="d-flex align-items-center gap-2 me-4">
                    {getMemberTierImg() && (
                      <img
                        src={getMemberTierImg()}
                        height={"24px"}
                        width={"48px"}
                      />
                    )}
                    <div className={styles["rop_member_id"]}>
                      {profileDetails?.memberID}
                    </div>
                    <div className={styles["rop_miles"]}>
                      {profileDetails?.remainingMiles}
                      {profileDetails?.remainingMiles && " Miles"}
                    </div>
                  </div>
                  {(!props.isWidget ||
                    (props.isWidget &&
                      props?.widgetParams?.params?.redemption)) && (
                    <TGToggle
                      variant="secondary"
                      buttonExists={false}
                      label={t("label_book_widget_redeem_miles")}
                      onClick={handleRedeemMilesSelection}
                      active={props.redeemState}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.flexWrapper}>
              <div className={styles["redeem-label"]}>
                {t("label_redemption_booking-widget-redeem_miles_for")}{" "}
                <span
                  onClick={() => setShowInfo(true)}
                  className={styles["info-icon"]}
                >
                  <TGIcon icon="information-icon" size="" fillColor="" />
                </span>
              </div>
              <div className={styles["redeem-option-wrapper"]}>
                {renderRadioComponent()}
              </div>
            </div>
          </div>
          <div>{renderRedeemTabContent()}</div>
          {selectedItem !== BookFlightTab.MultiCity && (
            <div className={styles.buttonContainer}>
              <Button
                className={styles.buttonStyleContainer}
                onClick={handleSearchFlight}
                disabled={
                  !(
                    state?.From &&
                    Object.keys(state?.From)?.length &&
                    state?.To &&
                    Object.keys(state?.To)?.length &&
                    state?.date !== ""
                  )
                }
                aria-label="Search Flights Button"
              >
                <span>{t("button_search_flights")}</span>
              </Button>
            </div>
          )}
        </>
      )}
      {!isDesktopView && !props.isOffCanvas ? (
        <>
          <div style={{ paddingLeft: "0", paddingRight: "0" }}>
            {renderRedeemMobileTabContent()}
          </div>
          {currentTabIndex?.[1] !== "2" && (
            <div className="px-2 mb-4">
              <TGButton
                label={t("button_search_flights")}
                variant="primary"
                buttonSize="lg"
                onClick={handleSearchFlight}
                customClass="bookingButtonContainer"
                disabled={
                  !(
                    state?.From &&
                    Object.keys(state?.From).length &&
                    state?.To &&
                    Object.keys(state?.To).length &&
                    state?.date !== ""
                  )
                }
              />
            </div>
          )}
        </>
      ) : (
        <>
          <Offcanvas
            show={props.isOffCanvas}
            onHide={() => {
              if (props.onHideCanvas) props.onHideCanvas();
            }}
            placement="bottom"
            className={`${styles["tg-select-offcanvas"]}`}
            backdropClassName={""}
          >
            <Offcanvas.Header
              closeButton
              className={styles["tg-select-offcanvas-header"]}
            >
              <Offcanvas.Title className={styles["tg-select-offcanvas-title"]}>
                {t("label_redemption_edit_search")}
              </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body className={styles["multi-city-body-wrapper"]}>
              <div style={{ paddingLeft: "0", paddingRight: "0" }}>
                {renderRedeemMobileTabContent()}
              </div>
              {currentTabIndex?.[1] !== "2" && (
                <div className="px-2 mb-4">
                  <TGButton
                    label={t("button_search_flights")}
                    variant="primary"
                    buttonSize="lg"
                    onClick={handleSearchFlight}
                    customClass="bookingButtonContainer"
                    disabled={
                      !(
                        state?.From &&
                        Object.keys(state?.From).length &&
                        state?.To &&
                        Object.keys(state?.To).length &&
                        state?.date !== ""
                      )
                    }
                  />
                </div>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
      {showInfo && (
        <TGModal
          show={showInfo}
          headingClass="headerText"
          handleClose={() => setShowInfo(false)}
          centered={true}
          customOffCanvasClass={
            currentVendor === 1
              ? styles["custom-mobile-modal"]
              : styles["custom-mobile-modal-star"]
          }
        >
          <div className={styles["modal-wrapper"]}>
            {currentVendor === 1 ? renderTGInfo() : renderStarInfo()}
          </div>
        </TGModal>
      )}
    </>
  );
};

export default RedemptionBookingWidget;
