import React, { useEffect, useRef, useState } from "react";
import styles from "./redemption-multi-city.module.scss";
import { AirportData, MultiCityFlightProps } from "../../../models/models";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import TGDateCalendar from "../../../../../shared/tg-new-datepicker";
import TGIconLabel from "../../../../../shared/tg-icon-label";
import MultiCityAutosuggest from "../../booking-widget/multi-city/multi-city-autosuggest";
import moment from "moment";
import TGButton from "../../../../../shared/tg-button";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import TGFloatingSelect from "../../../../../shared/tg-select";
import {
  saveRedemptionBookingData,
  clearSelectedFlightIndex,
  disable_continue_btn,
  saveRuleSetId,
} from "../../../slice/flightInfoSlice";
import { useNavigate } from "react-router";
import { saveRedemptionMultiCityFieldData } from "../../../slice/redemptionSlice";
import { resetPersistStore } from "../../../../../store";

const MultiCity: React.FC<MultiCityFlightProps> = (
  props: MultiCityFlightProps
) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const addFlightRef: any = useRef(null);
  const [isPassengerFocused, setIsPassengerFocused] = useState(false);
  const [isAddFlightFocused, setAddFlightFocused] = useState(false);
  const isMobile = useScreenSize()?.deviceSize?.width < 768;
  const redemptionSelector = useSelector(
    (state: RootState) =>
      state?.osciReducer?.backTrackingAirport?.backTrackingAirportInfo?.data
        ?.airAwardData
  );
  const dispatch = useDispatch();
  const [rows, setRows] = useState<any>([
    {
      id: "flight1",
      from: {},
      to: {},
      date: "",
    },
    {
      id: "flight2",
      from: {},
      to: {},
      date: "",
    },
  ]);
  const [rowsFocused, setRowsFocused] = useState([
    {
      id: "flight1",
      from: false,
      to: false,
      date: false,
    },
    {
      id: "flight2",
      from: false,
      to: false,
      date: false,
    },
  ]);

  const [passengerCount, setPassengerCount] = useState<any>("1");

  const [paxData, setPaxData] = useState([]);

  const airportData: AirportData | any = useSelector(
    (state: RootState) =>
      state?.osciReducer?.airAwardAirport?.airAwardAirportInfo?.data?.data
  );
  const nomineeDetailsInfo = useSelector(
    (state: RootState) => state?.osciReducer?.nominee?.nomineeData
  );
  const selectedFieldData = useSelector(
    (state: RootState) => state?.osciReducer?.redemption?.multiCityFieldArr
  );

  useEffect(() => {
    resetPersistStore();
  }, []);

  useEffect(() => {
    if (selectedFieldData?.length > 0) {
      setRows(selectedFieldData);
    }
  }, [selectedFieldData]);

  useEffect(() => {
    if (Object.keys?.(nomineeDetailsInfo)?.length) {
      const nomineeCount = nomineeDetailsInfo?.nomineeDetail?.length;
      const maxPaxCount =
        !isNaN(nomineeCount) && nomineeCount >= 4 ? 4 : nomineeCount + 1;
      const resultArr = [...Array(maxPaxCount)].map((x: any, index: number) => {
        return {
          listName: index + 1,
        };
      });
      setPaxData(resultArr);
    }
  }, [nomineeDetailsInfo]);

  useEffect(() => {
    const timeoutTime = isMobile ? 500 : 200;
    if (
      isAddFlightFocused &&
      rows?.every(
        (x: any) =>
          Object?.keys(x?.from).length > 0 &&
          Object?.keys(x?.to)?.length > 0 &&
          x?.date !== ""
      )
    ) {
      setTimeout(() => {
        addFlightRef?.current?.focus();
      }, timeoutTime);
    }
  }, [isAddFlightFocused, rows, rowsFocused]);

  useEffect(() => {
    const arrRecentSearch: Array<any> = [];
    for (let x = 0; x < rows.length; x++) {
      arrRecentSearch.push({
        id: `flight${x + 1}`,
        from: false,
        to: false,
        date: false,
      });
    }
    setRowsFocused(arrRecentSearch);
  }, [rows.length]);

  useEffect(() => {
    if (
      rows?.[0]?.to &&
      Object.keys(rows?.[0]?.to)?.length > 0 &&
      rows?.[1] &&
      Object.keys(rows?.[1]?.from)?.length === 0
    ) {
      const arr = [...rows];
      const tempObj = {
        ...arr[1],
      };
      const tempData = rows?.[1].from;
      tempObj.from =
        Object.keys(tempData)?.length === 0 &&
        Object.keys(rows[0]?.to)?.length > 0 &&
        rows?.[0]?.to?.originDisp
          ? rows[0]?.to
          : "";
      arr[1] = tempObj;
      setRows([...arr]);
    }
  }, [Object.keys(rows?.[0]?.to)?.length]);

  const isBackTracking = (airportKey: string, comparableKey: string) => {
    const filteredObj = redemptionSelector?.filter(
      (z: any) => z?.origin === airportKey
    );
    const hasData = filteredObj?.[0]?.destination?.includes(comparableKey);
    return hasData;
  };

  const handleEnteredTransition = (selectedDate: any) => {
    if (selectedDate) {
      document
        .querySelectorAll(
          ".react-datepicker__day--selected:not(.react-datepicker__day--outside-month)"
        )[0]
        ?.parentNode?.parentNode?.parentNode?.scrollIntoView({
          block: "start",
        });
    } else {
      document
        .querySelectorAll(
          "[aria-disabled=false]:not(.react-datepicker__day--outside-month)"
        )?.[0]
        ?.parentNode?.parentNode?.parentNode?.scrollIntoView({
          block: "start",
        });
    }
  };

  const handleSubmit = () => {
    resetPersistStore();
    const ruleSetArr = [
      "HKT",
      "CNX",
      "CEI",
      "UBP",
      "UTH",
      "KKC",
      "URT",
      "KBV",
      "HDY",
    ];
    if (
      rows?.length === 2 &&
      rows?.[0]?.from?.airportCode === "BKK" &&
      rows?.[1]?.to?.airportCode === "BKK"
    ) {
      dispatch(saveRuleSetId(5));
    } else if (
      ruleSetArr?.includes(rows?.[0]?.from?.airportCode) &&
      !ruleSetArr?.includes(rows?.[1]?.to?.airportCode)
    ) {
      dispatch(saveRuleSetId(7));
    } else if (
      !ruleSetArr?.includes(rows?.[0]?.from?.airportCode) &&
      ruleSetArr?.includes(rows?.[1]?.to?.airportCode)
    ) {
      dispatch(saveRuleSetId(8));
    } else {
      dispatch(saveRuleSetId(6));
    }

    const finalReqArr = rows?.map((x: any, index: any) => {
      return {
        pax: passengerCount,
        from: x?.from,
        to: x?.to,
        triptype: "departure",
        date: x?.date,
        journeyType: "Multi-City",
        routeCount: rows.length,
        itneraryOrder: Number(x.id.replace("flight", "")),
        isBackTracking:
          index === 1
            ? isBackTracking(
                rows?.[0]?.from?.airportCode,
                rows?.[1]?.to?.airportCode
              )
            : index === 3
            ? isBackTracking(
                rows?.[2]?.from?.airportCode,
                rows?.[3]?.to?.airportCode
              )
            : false,
      };
    });
    dispatch(saveRedemptionBookingData(finalReqArr));
    dispatch(saveRedemptionMultiCityFieldData(rows));
    const elem = document?.getElementById(
      "redemption-select-flight-edit-widget"
    );
    if (elem) {
      elem.style.visibility = "hidden";
    }
    // show edit search summary once edited in select flight page
    if (props?.showEditSummary !== undefined) {
      props?.showEditSummary(false);
    }
    // clearing selected flight while landing widget page
    dispatch(clearSelectedFlightIndex());
    dispatch(disable_continue_btn(true));

    navigate(`/${i18n.language}/redemption/selectflight`);
  };

  const handleAddFlightRow = () => {
    if (rows.length < 4) {
      let tempRow = rows;
      tempRow = [
        ...tempRow,
        {
          id: `flight${rows.length + 1}`,
          from: rows[rows.length - 1]?.to || {},
          to: {},
          date: "",
        },
        {
          id: `flight${rows.length + 2}`,
          from: {},
          to: {},
          date: "",
        },
      ];
      setRows([...tempRow]);
      //focus case
      const tempRowFocused = rowsFocused;
      tempRowFocused.push({
        id: `flight${rowsFocused.length + 1}`,
        from: false,
        to: false,
        date: false,
      });
      setRowsFocused([...tempRowFocused]);
    }
    setAddFlightFocused(false);
    addFlightRef?.current?.blur();
  };

  const handleDeleteFlightRow = (i: any) => {
    setRows([{ ...rows[0] }, { ...rows[1] }]);

    //focus case
    let focusedIndex = 0;
    const focusedArr = rowsFocused?.map((y: any, inde: number) => {
      if (y.id !== i.id) {
        focusedIndex += 1;
        return {
          id: `flight${focusedIndex}`,
          from: false,
          to: false,
          date: false,
        };
      } else {
        focusedIndex = inde;
        return null;
      }
    });
    const tempFocusedArr: any = focusedArr?.filter((val: any) => val);
    setRowsFocused([...tempFocusedArr]);
  };

  const handleSelect = (
    value: AirportData,
    fieldName: string,
    currentIndex: number
  ) => {
    let temp = {
      ...rows[currentIndex],
      [fieldName]: value,
    };
    let tempArr = [...rows];
    if (fieldName === "from") {
      if (value?.airportCode === rows[currentIndex]?.to?.airportCode) {
        temp = { ...temp, to: {} };
      }
    } else if (fieldName === "to") {
      if (value?.airportCode === rows[currentIndex]?.from?.airportCode) {
        temp = { ...temp, from: {} };
      }
    }
    if (currentIndex < rows?.length) {
      const updatedRows = rows?.map((z: any, indexN: number) => {
        return {
          ...z,
          from: indexN <= currentIndex ? z?.from : {},
          to: indexN < currentIndex ? z?.to : {},
        };
      });
      tempArr = [...updatedRows];
    }
    tempArr[currentIndex] = temp;
    if (tempArr[0]?.from?.airportCode === "BKK") {
      tempArr = [{ ...tempArr[0] }, { ...tempArr[1] }];
    }
    setRows([...tempArr]);

    //focus case
    const tempFocused = {
      ...rowsFocused[currentIndex],
      from: fieldName === "from" && false,
      to: fieldName === "from",
      date: fieldName === "to",
    };
    const tempArrFocused = [...rowsFocused];
    tempArrFocused[currentIndex] = tempFocused;
    setRowsFocused([...tempArrFocused]);
  };

  const handleClear = (currentField: string, index: number) => {
    let tempArr = [...rows];
    if (index < rows?.length) {
      const updatedRows = rows?.map((z: any, indexN: number) => {
        return {
          ...z,
          from: indexN <= index ? z?.from : {},
          to: indexN < index ? z?.to : {},
        };
      });
      tempArr = [...updatedRows];
    }
    setRows([...tempArr]);
  };

  const handleSelectedDate = (item: any, index: number) => {
    const temp = {
      ...rows[index],
      date: item?.stDate,
    };
    const tempArr = [...rows];
    tempArr[index] = temp;
    for (let x = index + 1; x < rows.length; x++) {
      const dateValue = item?.stDate >= tempArr[x].date ? "" : tempArr[x].date;
      const tempDateObj = {
        ...rows[x],
        date: dateValue,
      };
      tempArr[x] = tempDateObj;
    }
    setRows([...tempArr]);

    // focus case
    let tempArrFocused = [...rowsFocused];
    if (index + 1 < rowsFocused?.length) {
      const tempFocused = {
        ...rowsFocused[index + 1],
        from: true,
      };
      tempArrFocused[index + 1] = tempFocused;
    }
    if (index < rowsFocused?.length) {
      const tempCurrentFocused = {
        ...rowsFocused[index],
        date: false,
      };
      tempArrFocused[index] = tempCurrentFocused;
    }
    setRowsFocused([...tempArrFocused]);
    if (index === 3) {
      setIsPassengerFocused(true);
    } else if (index < 4) {
      setTimeout(() => {
        setAddFlightFocused(true);
      }, 200);
    }
  };

  const handleSwap = (index: number) => {
    if (
      Object.keys(rows[index]?.from)?.length > 0 &&
      Object.keys(rows[index]?.to)?.length > 0
    ) {
      const temp = {
        ...rows[index],
        from: rows[index]?.to,
        to: rows[index]?.from,
      };
      const tempArr = [...rows];
      tempArr[index] = temp;
      setRows([...tempArr]);
    }
  };

  const renderFromOptions = (idx: number) => {
    if (idx === 0) {
      return airportData;
    }
    //Flight from BKK
    else if (
      rows?.[0]?.from?.airportCode === "BKK" &&
      rows?.[0]?.to &&
      idx === 1
    ) {
      return airportData?.filter((x: any) => x?.zone === rows?.[0]?.to?.zone);
    }
    //Regional Route
    else if (
      rows?.[0]?.from?.airportCode !== "BKK" &&
      rows?.[0]?.from?.multiZone < 6 &&
      idx === 1
    ) {
      return airportData?.filter((z: any) => z?.airportCode === "BKK");
    } else if (
      rows?.[0]?.from?.airportCode !== "BKK" &&
      rows?.[0]?.from?.multiZone >= 6 &&
      idx === 1
    ) {
      return airportData?.filter((z: any) => z?.airportCode === "BKK");
    } else if (rows?.length > 2 && idx === 2 && rows?.[idx - 1]?.to) {
      return airportData?.filter(
        (x: any) => x?.multiZone === rows?.[idx - 1]?.to?.multiZone
      );
    } else if (rows?.length > 2 && idx === 3 && rows?.[idx - 1]?.to) {
      return airportData?.filter((z: any) => z?.airportCode === "BKK");
    } else return airportData;
  };

  const renderTOOptions = (idx: number) => {
    if (idx === 0 && !rows?.[0]?.from?.airportCode) {
      return airportData;
    }
    //Flight from BKK
    else if (
      rows?.[0]?.from?.airportCode === "BKK" &&
      rows?.[1]?.from &&
      idx === 1
    ) {
      return airportData?.filter((z: any) => z?.airportCode === "BKK");
    }
    //Regional Route
    else if (
      rows?.[0]?.from?.countryCode === "TH" &&
      rows?.[0]?.from?.multiZone < 6 &&
      idx === 1
    ) {
      return airportData?.filter((z: any) => z?.countryCode !== "TH");
    } else if (
      rows?.[0]?.from?.airportCode !== "BKK" &&
      rows?.[0]?.from?.multiZone < 6 &&
      idx === 0
    ) {
      return airportData?.filter((z: any) => z?.airportCode === "BKK");
    } else if (
      rows?.[0]?.from?.airportCode !== "BKK" &&
      rows?.[0]?.from?.multiZone >= 6 &&
      idx === 0
    ) {
      return airportData?.filter((z: any) => z?.airportCode === "BKK");
    } else if (
      (rows?.[0]?.from?.airportCode !== "BKK" &&
        rows?.[0]?.from?.multiZone >= 6 &&
        idx === 1) ||
      (rows?.[0]?.from?.airportCode !== "BKK" &&
        rows?.[0]?.from?.countryCode === "TH" &&
        idx === 1)
    ) {
      return airportData?.filter(
        (z: any) =>
          z?.airportCode !== rows?.[0]?.from?.airportCode &&
          z?.airportCode !== rows?.[idx]?.from?.airportCode
      );
    } else if (rows?.length > 2 && idx === 2 && rows?.[idx - 1]?.to) {
      return airportData?.filter((x: any) => x?.airportCode === "BKK");
    } else if (rows?.length > 2 && idx === 3 && rows?.[idx]?.from) {
      return airportData?.filter(
        (z: any) => z?.airportCode === rows?.[0]?.from?.airportCode
      );
    } else
      return airportData?.filter(
        (z: any) => z?.airportCode !== rows[idx]?.from?.airportCode
      );
  };

  const handleDropdownBlur = (indexNo: number, field: string) => {
    const tempFocused: any = [...rowsFocused];
    tempFocused[indexNo][field] = false;
    setRowsFocused([...tempFocused]);
  };

  const handlePassenger = (e: any, selectedValue: any) => {
    setPassengerCount(selectedValue?.listName);
  };

  const handleDateBlur = (idxN: number, obj: any) => {
    const temp = [...rowsFocused];
    temp[idxN] = { ...temp[idxN], ...obj };
    setRowsFocused([...temp]);
  };

  const handleMinDate = (indexN: number) => {
    //Regional Route
    if (
      rows?.[0]?.from?.airportCode !== "BKK" &&
      rows?.[0]?.from?.multiZone < 6
    ) {
      //Backtracking Case
      if (
        isBackTracking(
          rows?.[0]?.from?.airportCode,
          rows?.[1]?.to?.airportCode
        ) &&
        indexN === 1
      ) {
        return new Date(moment(rows[0]?.date));
      } else if (
        isBackTracking(
          rows?.[2]?.from?.airportCode,
          rows?.[3]?.to?.airportCode
        ) &&
        indexN === 3
      ) {
        return new Date(moment(rows[2]?.date));
      } else if (indexN === 0) {
        return moment(new Date()).add(1, "day")["_d"];
      } else {
        return new Date(moment(rows[indexN - 1]?.date));
      }
    } else if (indexN > 1) {
      if (
        rows[indexN - 1].date !== "" &&
        rows[indexN - 2].date !== "" &&
        moment(rows[indexN - 1].date).format("YYYY-MM-DD") ===
          moment(rows[indexN - 2].date).format("YYYY-MM-DD")
      ) {
        return moment(rows[indexN - 2].date).add(1, "day")["_d"];
      } else {
        const tempArr = [...rows];
        const arr = tempArr?.splice(0, indexN);
        let inde = arr.length;
        while (inde-- && !arr[inde]?.date);
        return arr[inde]?.date;
      }
    } else if (indexN === 0) {
      return moment(new Date()).add(1, "day")["_d"];
    } else {
      return indexN - 1 >= 0 && rows[indexN - 1]?.date;
    }
  };

  const handleMaxDate = (indexN: number) => {
    //Regional Route
    if (
      rows?.[0]?.from?.airportCode !== "BKK" &&
      rows?.[0]?.from?.multiZone < 6 &&
      rows?.[1]?.to?.countryCode !== "TH" &&
      rows?.[0]?.from?.countryCode !== "TH"
    ) {
      //Backtracking Case
      if (
        isBackTracking(
          rows?.[0]?.from?.airportCode,
          rows?.[1]?.to?.airportCode
        ) &&
        indexN === 1
      ) {
        return true;
      } else if (
        isBackTracking(
          rows?.[2]?.from?.airportCode,
          rows?.[3]?.to?.airportCode
        ) &&
        indexN === 3
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      (rows?.[0]?.from?.countryCode !== "TH" &&
        rows?.[1]?.to?.countryCode === "TH" &&
        (indexN === 1 || indexN === 3)) ||
      (rows?.[0]?.from?.airportCode !== "BKK" &&
        rows?.[0]?.from?.countryCode === "TH" &&
        (indexN === 1 || indexN === 3))
    ) {
      return true;
    }
  };

  const renderFlightRow = (index: number, value: any) => {
    return (
      <div
        className={`${styles["multicity-flight-row-wrapper"]} d-flex flex-column w-100 gap-3`}
        key={index}
      >
        <div className="d-flex justify-content-between w-100">
          <span className={styles.flightText}>
            {t("label_flight_header")} {index + 1}
          </span>
          {rows?.length > 2 && index === 2 && (
            <span
              className={styles.flightText}
              onClick={() => handleDeleteFlightRow(value)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.0003 2.29169C5.74313 2.29169 2.29199 5.74283 2.29199 10C2.29199 14.2572 5.74313 17.7084 10.0003 17.7084C14.2575 17.7084 17.7087 14.2572 17.7087 10C17.7087 5.74283 14.2575 2.29169 10.0003 2.29169ZM1.04199 10C1.04199 5.05247 5.05277 1.04169 10.0003 1.04169C14.9479 1.04169 18.9587 5.05247 18.9587 10C18.9587 14.9476 14.9479 18.9584 10.0003 18.9584C5.05277 18.9584 1.04199 14.9476 1.04199 10ZM7.05838 7.05808C7.30246 6.814 7.69819 6.814 7.94227 7.05808L10.0003 9.11614L12.0584 7.05808C12.3025 6.814 12.6982 6.814 12.9423 7.05808C13.1863 7.30216 13.1863 7.69789 12.9423 7.94196L10.8842 10L12.9423 12.0581C13.1863 12.3022 13.1863 12.6979 12.9423 12.942C12.6982 13.186 12.3025 13.186 12.0584 12.942L10.0003 10.8839L7.94227 12.942C7.69819 13.186 7.30246 13.186 7.05838 12.942C6.81431 12.6979 6.81431 12.3022 7.05838 12.0581L9.11644 10L7.05838 7.94196C6.81431 7.69789 6.81431 7.30216 7.05838 7.05808Z"
                  fill="#6A6D87"
                />
              </svg>
            </span>
          )}
        </div>
        <div className={`${styles["multicity-main-container"]} d-flex gap-3`}>
          <div className={styles["multicity-destination-container"]}>
            <MultiCityAutosuggest
              label={t("label_book_widget_from")}
              customClassName="fromDropdownBorderRadius"
              onChange={() => {}}
              onSelect={handleSelect}
              searchPlaceHolder={t("label_multi_city_search_placeholder")}
              name="from"
              options={renderFromOptions(index)?.filter(
                (x: any) => x?.originDisp
              )}
              rowIndex={index}
              selectedPropsValue={rows[index]?.from}
              onClear={(i: number) => handleClear("from", i)}
              reverseArrow={false}
              recentAirport={[]}
              onClearRecentSearch={() => {}}
              isFocused={rowsFocused[index]?.from}
              onAutoSuggestBlur={() => handleDropdownBlur(index, "from")}
              listHeader={t("label_redemption_airports")}
            />
            <MultiCityAutosuggest
              label={t("label_book_widget_to")}
              customClassName="toDropdownBorderRadius"
              onChange={() => {}}
              onSelect={handleSelect}
              searchPlaceHolder={t("label_multi_city_search_placeholder")}
              name="to"
              options={renderTOOptions(index)?.filter(
                (z: any) => z?.destinationDisp
              )}
              rowIndex={index}
              selectedPropsValue={rows[index]?.to}
              onClear={(i: number) => handleClear("to", i)}
              recentAirport={[]}
              onClearRecentSearch={() => {}}
              isFocused={rowsFocused[index]?.to}
              onAutoSuggestBlur={() => handleDropdownBlur(index, "to")}
              listHeader={t("label_redemption_airports")}
            />
          </div>
          <div className={styles["multicity-date-container"]}>
            <TGDateCalendar
              label={t("label_book_widget_depart")}
              onChange={(e, label) => {}}
              type="1way"
              dateData={{ stDate: value?.date }}
              setDateData={(val: any) => handleSelectedDate(val, index)}
              onReset={() => {}}
              focused={{ date: rowsFocused[index]?.date }}
              setFocused={() => {}}
              minDate={handleMinDate(index)}
              isRedemption={handleMaxDate(index)}
              name="multicity"
              onDateBlur={(obj: any) => handleDateBlur(index, obj)}
              onEnter={() => handleEnteredTransition(value?.date)}
              hideFare={true}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {rows?.map((x: any, index: number) => {
        return renderFlightRow(index, x);
      })}
      {rows?.length < 4 && rows?.[0]?.from?.airportCode !== "BKK" && (
        <button
          className={`${styles["multi-city-add-flight"]} mb-4 d-flex justify-content-start align-items-center`}
          onClick={handleAddFlightRow}
          aria-hidden="true"
          ref={addFlightRef}
          onBlur={() => setAddFlightFocused(false)}
          disabled={
            !rows?.every(
              (x: any) =>
                x?.from &&
                Object.keys(x?.from)?.length > 0 &&
                x?.to &&
                Object.keys(x?.to)?.length > 0 &&
                x?.date !== ""
            )
          }
        >
          <TGIconLabel
            icon="plus-icon"
            label={t("button_add_flight")}
            position="left"
            optionalClassName="containerBorder"
          />
        </button>
      )}
      {rows?.length === 4 && isMobile && (
        <div className={styles["multi-city-max-limit"]}>
          {t("label_multi_city_redemption_flight_limit")}
        </div>
      )}
      <div
        className={`${styles["multi-city-end-wrapper"]} d-flex justify-content-between`}
      >
        <div className={styles["multi-city-passenger-wrapper"]}>
          <div className={styles["multi-city-passenger-popup-wrapper"]}>
            <TGFloatingSelect
              label={t("label_book_widget_passengers")}
              listData={paxData}
              onChange={() => {}}
              isLoading={false}
              isReadOnly={true}
              defaultValue={"1"}
              onSelect={handlePassenger}
              focused={isPassengerFocused}
              disableSearch={true}
            />
          </div>
        </div>
        <TGButton
          label={t("button_search_flights")}
          variant="primary"
          buttonSize="lg"
          customClass="bookingButtonContainer"
          onClick={handleSubmit}
          disabled={
            !rows?.every(
              (x: any) =>
                x?.from &&
                Object?.keys(x?.from).length > 0 &&
                x?.to &&
                Object?.keys(x?.to)?.length > 0 &&
                x?.date !== ""
            )
          }
        />
      </div>
    </>
  );
};

export default MultiCity;
